import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ITopSearchConfig } from '@/new-top-search/top-search.config';
import { combineLatest, filter, take } from 'rxjs';
import { OffersFacade } from '@/_store/offers/offers.facade';
import { DEFAULT_PAGINATION_LIMIT, DEFAULT_PAGINATION_OFFSET } from '@/_store/offers/offers.types';

@Component({
	selector: 'app-top-search-filter-items',
	templateUrl: './top-search-filter-items.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopSearchFilterItemsComponent implements OnInit {
	@Input() filters: ITopSearchConfig[] = [];

	isDropdownOpenArray: boolean[] = [];

	private readonly urlParams$ = this.offersFacade.urlParams$;
	private readonly offset$ = this.offersFacade.offset$;
	private _valueChange = false;

	constructor(private readonly offersFacade: OffersFacade) {}

	ngOnInit(): void {
		this.isDropdownOpenArray = new Array(this.filters.length).fill(false);
	}

	changeOpenState(isOpen: boolean, i: number): void {
		this.isDropdownOpenArray[i] = isOpen;
	}

	set valueChange(value: boolean) {
		this._valueChange = value;
	}

	fetchOffers(): void {
		this.offersFacade.updateOffset(DEFAULT_PAGINATION_OFFSET);
		combineLatest([this.urlParams$, this.offset$])
			.pipe(
				take(1),
				filter(() => this._valueChange),
			)
			.subscribe(([params, offset]) => {
				this.valueChange = false;
				this.offersFacade.clearOfferList();
				this.offersFacade.fetchOfferList(
					params + `&limit=${DEFAULT_PAGINATION_LIMIT}&offset=${offset}`,
					params + `&limit=${DEFAULT_PAGINATION_LIMIT}&offset=${offset + DEFAULT_PAGINATION_LIMIT}`,
				);
				this.offersFacade.updateQueryParams(params);
			});
	}
}
