import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Row, RowValue } from '@/new-top-search/top-search-dropdown-item/top-search-dropdown-item.types';
import { TrackByIndex } from '@/_utils/track-by-index';

@Component({
	selector: 'app-top-search-counter',
	templateUrl: './top-search-counter.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopSearchCounterComponent extends TrackByIndex {
	@Input() rows: Row[] = [];
	@Input() counters: { [key: string]: number } = {};
	@Output() rowValueChange: EventEmitter<RowValue> = new EventEmitter<RowValue>();

	valueChange(value: number, row: Row): void {
		this.rowValueChange.emit({ value: value, type: row.rowName });
	}
}
