import { ApiOfferFilter, ApiOfferList } from '@/_store/offers/offers.types';
import { Result } from '@/common/data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class OffersApiService {
	private readonly api: string = environment.apiUrl;

	constructor(private readonly httpClient: HttpClient) {}

	getOfferFilter(): Observable<ApiOfferFilter> {
		return this.httpClient.get<ApiOfferFilter>(`${this.api}www/offer_filter/`);
	}

	getOffers(params: string, timezone: string): Observable<Result<ApiOfferList>> {
		return this.httpClient.get<Result<ApiOfferList>>(`${this.api}www/offer/${params}&timezone=${timezone}`);
	}

	getBoards(id: number): Observable<string[]> {
		return this.httpClient.get<string[]>(`${this.api}www/product/${id}/boards/`);
	}
}
