<app-search
	*ngIf="search.show"
	[disabled]="disabled"
	[placeholder]="search?.options?.placeholder"
	(searchUpdated)="searchUpdated.emit($event)"
></app-search>

<div class="flex flex-col flex-wrap z-50 h-auto">
	<div *ngIf="checkboxesForm" class="flex flex-col flex-wrap">
		<form
			[formGroup]="checkboxesForm"
			class="h-[60vh] lg:h-[300px] overflow-y-auto overflow-x-hidden text-xs w-full"
		>
			<div *ngIf="options.length; else emptyState" class="w-3/4 lg:w-full overflow-hidden">
				<mat-checkbox
					color="primary"
					class="text-mainColor"
					#toggleAllCheckbox
					[checked]="(toggleAllCheckbox$ | async) === CheckboxSelected.ALL"
					[disabled]="disabled"
					[indeterminate]="(toggleAllCheckbox$ | async) === CheckboxSelected.SOME"
					(change)="toggleAll($event.checked)"
				>
					{{ toggleAllCheckbox.checked ? 'Odznacz wszystko' : 'Zaznacz wszystko' }}
				</mat-checkbox>
			</div>
			<div class="w-3/4 lg:w-full overflow-hidden flex flex-col">
				<ng-container *ngFor="let option of options; trackBy: trackById">
					<mat-checkbox
						color="primary"
						[disabled]="disabled"
						[formControlName]="option.id"
						class="text-mainColor"
					>
						{{ option.name }}
					</mat-checkbox>
				</ng-container>
			</div>
		</form>
	</div>
</div>
<ng-template #emptyState>
	<p class="h-full flex justify-center items-center text-xl text-mainColor">Brak wyników!</p>
</ng-template>
