const POLISH_LETTERS_REGEX = /[ąćęłńóśźż]/g;
const NORMAL_LETTERS_MAP: { [key: string]: string } = {
	ą: 'a',
	ć: 'c',
	ę: 'e',
	ł: 'l',
	ń: 'n',
	ó: 'o',
	ś: 's',
	ź: 'z',
	ż: 'z',
};

export function replacePolishLetters(str: string): string {
	return str.replace(POLISH_LETTERS_REGEX, (match) => NORMAL_LETTERS_MAP[match]);
}
