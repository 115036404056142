import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	inject,
	Inject,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
} from '@angular/core';
import { OffersFacade } from '@/_store/offers/offers.facade';
import {
	TopSearchAdvancedConfig,
	TopSearchMainConfig,
	TopSearchMobileConfig,
} from '@/new-top-search/top-search.config';
import { GeneralDataFacade } from '@/_store/general/general.facade';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { combineLatest, EMPTY, filter, fromEvent, of, Subject, take, takeUntil, withLatestFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { createQueryParams, setSelectedValueBaseOnParams } from '@/_store/offers/offers.utils';
import { Selected } from '@/_store/offers/offers.types';
import { deepCompare } from '@/_utils/objects/deep-compare';
import { switchMap } from 'rxjs/operators';

@Component({
	selector: 'app-new-top-search',
	templateUrl: './top-search.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopSearchComponent implements OnInit, OnDestroy {
	@Input() isOfferList = false;
	isMobile$ = this.generalDataFacade.isMobile$;
	isSticky = false;

	readonly TopSearchMainConfig = TopSearchMainConfig;
	readonly TopSearchAdvancedConfig = TopSearchAdvancedConfig;
	readonly TopSearchMobileConfig = TopSearchMobileConfig;

	urlParams$ = this.offersFacade.urlParams$;

	private readonly topSearchTopPosition = 115;
	private readonly destroy$: Subject<void> = new Subject<void>();
	private readonly activateRoute = inject(ActivatedRoute);

	constructor(
		@Inject(DOCUMENT) private readonly document: Document,
		@Inject(PLATFORM_ID) private readonly platformId: string,
		private readonly offersFacade: OffersFacade,
		private readonly generalDataFacade: GeneralDataFacade,
		private readonly ngZone: NgZone,
		private readonly cdr: ChangeDetectorRef,
		private readonly router: Router,
	) {}

	ngOnInit(): void {
		if (isPlatformServer(this.platformId)) {
			return;
		}

		combineLatest([
			this.offersFacade.filterOptions$,
			this.activateRoute.queryParams,
			this.offersFacade.offerFiltersAreEmpty$,
			this.offersFacade.selected$,
		])
			.pipe(
				take(1),
				switchMap(([filterOptions, params, isEmpty, selected]) => {
					if (isEmpty) {
						this.offersFacade.fetchOfferFilter();
						return EMPTY;
					}

					const selectedFromParams: Selected = setSelectedValueBaseOnParams(filterOptions, params);
					return of({ filterOptions, params, selected, selectedFromParams });
				}),
				filter(({ selectedFromParams, selected }) => !deepCompare(selectedFromParams, selected)),
			)
			.subscribe(({ selectedFromParams }) => {
				this.offersFacade.updateSelectedOptions(selectedFromParams);
				this.offersFacade.reloadOfferList();
			});

		this.observeStickyTopSearch();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	goToOfferList(): void {
		this.urlParams$.pipe(take(1)).subscribe((params) => {
			const queryParams = createQueryParams(params);
			this.router.navigate(['/wakacje-autokarem'], {
				queryParams,
			});
		});
	}

	private observeStickyTopSearch(): void {
		this.ngZone.runOutsideAngular(() => {
			fromEvent(window, 'scroll')
				.pipe(takeUntil(this.destroy$), withLatestFrom(this.isMobile$))
				.subscribe((value) => {
					const isMobile = value[1];
					if (isMobile) return;
					const top = window.scrollY || this.document.documentElement.scrollTop;
					const previousStickyState = this.isSticky;
					this.isSticky = top > this.topSearchTopPosition;
					if (previousStickyState !== this.isSticky) {
						this.cdr.detectChanges();
					}
				});
		});
	}
}
