import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { OfferDropdownItemMode } from '@/offer/new-offer-configurator/offer-dropdown-item/offer-dropdown-item.component';
import { OffersFacade } from '@/_store/offers/offers.facade';
import { Observable } from 'rxjs';
import { ITopSearchConfig } from '@/new-top-search/top-search.config';

@Component({
	selector: 'app-top-search-filter',
	templateUrl: './top-search-filter.component.html',
	styleUrls: ['./top-search-filter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopSearchFilterComponent implements OnInit {
	@Input() filter: ITopSearchConfig;

	readonly OfferDropdownItemMode = OfferDropdownItemMode;

	offersFacade = inject(OffersFacade);
	selected$: Observable<string | number>;

	ngOnInit(): void {
		this.selected$ = this.offersFacade.getHeaderValue(this.filter.id);
	}
}
