<div
	class="lg:bg-white flex lg:flex-row flex-wrap lg:border-2 lg:rounded-xl border-none text-xl-right text-center h-full overflow-x-hidden"
>
	<div class="flex flex-col justify-start md:justify-between w-full md:h-auto">
		<app-counter
			*ngFor="let item of rows; let i = index; trackBy: trackByIndex"
			[rowName]="item.rowName"
			[options]="item.options"
			[counter]="counters[item.rowName]"
			(counterChange)="valueChange($event, item)"
		>
		</app-counter>
	</div>
</div>
