import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { OffersFacade } from '@/_store/offers/offers.facade';
import {
	CheckboxFilterValue,
	MultiChoiceComponent,
} from '@/new-top-search/top-search-dropdown-item/multi-choice/multi-choice.component';
import {
	CounteringModes,
	DropdownType,
	MultiChoiceModes,
	Participant,
	Row,
	RowValue,
	TopSearchDropdownItemMode,
} from '@/new-top-search/top-search-dropdown-item/top-search-dropdown-item.types';
import { BehaviorSubject, combineLatest, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { BaseFilter, OfferDateRange, Selected } from '@/_store/offers/offers.types';
import { DateRange } from '@angular/material/datepicker';
import { DropdownService } from '@/common/dropdown/dropdown.service';
import { TopSearchDatePickerComponent } from '@/new-top-search/top-search-dropdown-item/top-search-date-picker/top-search-date-picker.component';
import { replacePolishLetters } from '@/_utils/strings/replace-polish-letters';

@Component({
	selector: 'app-top-search-dropdown-item',
	templateUrl: './top-search-dropdown-item.component.html',
	styleUrls: ['./top-search-dropdown-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopSearchDropdownItemComponent implements OnInit, OnDestroy {
	@Input() mode!: TopSearchDropdownItemMode;

	@Output() valueChange: EventEmitter<boolean> = new EventEmitter();

	@ViewChild(TopSearchDatePickerComponent, { static: false })
	topSearchDatePickerComponent: TopSearchDatePickerComponent;

	@ViewChild(MultiChoiceComponent, { static: false })
	multiChoiceComponent: MultiChoiceComponent;

	type!: DropdownType;
	DropdownType = DropdownType;
	offersDateRange$: Observable<DateRange<Date>> = this.offersFacade.offersDateRange$;
	selectedDateRange$: Observable<DateRange<Date>> = this.offersFacade.selectedDateRange$;
	rows$: Observable<Row[]> = of([]);
	counters$: Observable<{ [key: number]: number }> = of({});
	searchingValue$: BehaviorSubject<string> = new BehaviorSubject<string>('');
	ownTransport$: Observable<boolean> = this.offersFacade.ownTransport$;
	ownTransport = false;
	selected$ = this.offersFacade.selected$.pipe(
		map((selected: Selected) => selected[this.mode as keyof Selected] as number[]),
	);
	options$: Observable<BaseFilter[]> = combineLatest([
		this.searchingValue$,
		this.offersFacade.getOptions(this.mode),
	]).pipe(
		map(([searching, options]) =>
			options.filter((obj) =>
				replacePolishLetters(obj.name.toLowerCase()).includes(replacePolishLetters(searching.toLowerCase())),
			),
		),
	);

	readonly TopSearchDropdownItemMode = TopSearchDropdownItemMode;

	private readonly destroy$: Subject<void> = new Subject();

	constructor(
		private readonly offersFacade: OffersFacade,
		private readonly dropdownService: DropdownService,
	) {}

	ngOnInit(): void {
		this.options$ = this.setMultiChoiceOptions();
		this.setCounterValues();
		this.setDropdownType();
		if (this.mode === TopSearchDropdownItemMode.STOPS) {
			this.ownTransport$
				.pipe(takeUntil(this.destroy$))
				.subscribe((ownTransport) => (this.ownTransport = ownTransport));
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	filtersActions(filter: CheckboxFilterValue | OfferDateRange | RowValue): void {
		filter = filter as CheckboxFilterValue;
		this.valueChange?.emit(true);
		switch (this.mode) {
			case TopSearchDropdownItemMode.LOCATIONS:
				filter.value
					? this.offersFacade.addLocationsFilterOption(filter.id)
					: this.offersFacade.removeLocationsFilterOption(filter.id);
				break;
			case TopSearchDropdownItemMode.KINDS:
				filter.value
					? this.offersFacade.addKindsFilterOption(filter.id)
					: this.offersFacade.removeKindsFilterOption(filter.id);
				break;
			case TopSearchDropdownItemMode.BOARDS:
				filter.value
					? this.offersFacade.addBoardsFilterOption(filter.id)
					: this.offersFacade.removeBoardsFilterOption(filter.id);
				break;
			case TopSearchDropdownItemMode.TAGS:
				filter.value
					? this.offersFacade.addTagsFilterOption(filter.id)
					: this.offersFacade.removeTagsFilterOption(filter.id);
				break;
			case TopSearchDropdownItemMode.CONVENIENCES:
				filter.value
					? this.offersFacade.addConveniencesFilterOption(filter.id)
					: this.offersFacade.removeConveniencesFilterOption(filter.id);
				break;
			case TopSearchDropdownItemMode.STOPS:
				filter.value
					? this.offersFacade.addStopsFilterOption(filter.id)
					: this.offersFacade.removeStopsFilterOption(filter.id);
				break;
			case TopSearchDropdownItemMode.DATE_RANGE:
				filter = filter as unknown as OfferDateRange;
				this.offersFacade.updateOffersDateRange(filter);
				break;
			case TopSearchDropdownItemMode.PARTICIPANTS:
				filter = filter as unknown as RowValue;
				if (filter.type === Participant.ADULTS) {
					this.offersFacade.updateAdultsCount(filter.value);
				}
				if (filter.type === Participant.CHILDREN) {
					this.offersFacade.updateChildrenCount(filter.value);
				}
				break;
			case TopSearchDropdownItemMode.NIGHTS:
				filter = filter as unknown as RowValue;
				this.offersFacade.updateNights(filter);
				this.offersFacade.updateNightsOptions(filter);
				break;
			default:
				const value: never = this.mode;
				throw new Error(`There is no option like ${value}`);
		}
	}

	clearFilterValue(): void {
		if (this.type === DropdownType.MULTI) {
			this.multiChoiceComponent.toggleAll(false);
		}
		if (this.type === DropdownType.DATE) {
			this.topSearchDatePickerComponent.clearDateRange();
		}
	}

	updateOwnTransport(ownTransport: boolean): void {
		this.offersFacade.updateOwnTransport(ownTransport);
		this.valueChange?.emit(true);
	}

	closeDropdown(): void {
		this.dropdownService.dispatchCloseDropdown();
	}

	private setMultiChoiceOptions(): Observable<BaseFilter[]> {
		return combineLatest([this.searchingValue$, this.offersFacade.getOptions(this.mode)]).pipe(
			map(([searching, options]) =>
				options.filter((obj) =>
					replacePolishLetters(obj.name.toLowerCase()).includes(
						replacePolishLetters(searching.toLowerCase()),
					),
				),
			),
		);
	}

	private setCounterValues(): void {
		if (this.mode === TopSearchDropdownItemMode.NIGHTS) {
			this.rows$ = this.offersFacade.nightsRows$;
			this.counters$ = this.offersFacade.nightCounters$;
		} else {
			this.rows$ = this.offersFacade.participantsRows$;
			this.counters$ = this.offersFacade.participantCounters$;
		}
	}

	private setDropdownType(): void {
		switch (true) {
			case MultiChoiceModes.includes(this.mode):
				this.type = DropdownType.MULTI;
				break;
			case CounteringModes.includes(this.mode):
				this.type = DropdownType.COUNTER;
				break;
			case this.mode === TopSearchDropdownItemMode.DATE_RANGE:
				this.type = DropdownType.DATE;
				break;
			default:
				throw new Error('Invalid mode value!');
		}
	}
}
