<section
	[ngClass]="{ '!fixed top-0': isSticky && (isMobile$ | async) === false }"
	class="w-full md:z-[1000] relative md:absolute mt-[60px] md:mt-0"
>
	<div
		class="bg-mainColor lg:bg-opacity-60 mx-auto my-0 shadow-mainColor w-full relative flex justify-center flex-col md:z-[1000]"
	>
		<article class="lg:w-[1440px] lg:mx-auto lg:flex items-center">
			<div class="lg:flex lg:flex-wrap my-4 w-full gap-[15px]">
				<ng-container *ngIf="(isMobile$ | async) === false; else mobileFilters">
					<div class="lg:flex lg:flex-wrap lg:flex-[1_0_0%] px-2 mb-4 lg:mb-0">
						<div
							class="flex flex-col md:flex-row md:justify-between md:items-center w-full flex-1 max-w-[100%] mb-1 lg:mb-0 relative md:gap-2"
						>
							<app-top-search-filter-items
								class="w-full"
								[filters]="TopSearchMainConfig"
							></app-top-search-filter-items>
						</div>
					</div>
					<app-top-search-filter-items
						*ngIf="isOfferList"
						class="w-full"
						[filters]="TopSearchAdvancedConfig"
					></app-top-search-filter-items>
				</ng-container>
				<ng-template #mobileFilters>
					<app-top-search-filter-items class="w-full" [filters]="TopSearchMobileConfig">
					</app-top-search-filter-items>
				</ng-template>
			</div>
			<div class="flex justify-center md:block mb-4 md:mb-0">
				<button
					*ngIf="!isOfferList"
					class="bg-mainYellow py-2 tracking-wide md:ms-3 font-semibold text-white rounded-[16px] h-fit w-60 hover:bg-[#e9a41a] transition-colors"
					(click)="goToOfferList()"
					data-testid="search-offers-btn"
				>
					<i class="fas fa-search pe-1"></i>
					Szukaj ofert
				</button>
			</div>
		</article>
	</div>
</section>
